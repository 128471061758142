<template>
  <div>
    <v-btn
      rounded
      v-bind="$attrs"
      color="indigo lighten-2"
      text
      class="v-btn--active text-capitalize ml-3"
    >
      <v-scroll-y-transition hide-on-leave>
        <span :key="totalParcels" class="indigo--text">
          {{ totalParcels }}
        </span>
      </v-scroll-y-transition>
      <span class="indigo--text">{{ makePlural(' parcel', totalParcels) }} </span>
    </v-btn>
    <v-btn
      rounded
      v-bind="$attrs"
      color="teal lighten-2"
      text
      class="v-btn--active text-capitalize ml-2"
    >
      <v-scroll-y-transition hide-on-leave>
        <span :key="totalAcres" class="teal--text">
          {{ totalAcres }}
        </span>
      </v-scroll-y-transition>
      <span class="teal--text">acres</span>
    </v-btn>
  </div>
</template>

<script>
import { rowTotal } from '../../utils';
export default {
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      total: {
        parcel: '',
        acres: ''
      }
    };
  },
  computed: {
    totalParcels() {
      return Number(this.value?.length);
    },

    totalAcres() {
      return parseFloat(rowTotal(this.value, 'acres'));
    }
  },
  methods: {
    makePlural(str, len = 1) {
      if (len > 1) return `${str}s`;
      return str;
    }
  }
};
</script>

<style>
</style>
